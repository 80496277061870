import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const commonRoutes = [
    {
        path: '/login',
        name: 'login',
        meta: { title: '' },
        component: () => import('../components/Login.vue'),
    },
    {
        path: '/404',
        name: '404',
        meta: { title: '' },
        component: () => import('../components/404.vue'),
    },
    { path: '/', redirect: '/home' },
]

// 本地所有的页面 需要配合后台返回的数据生成页面
export const asyncRoutes = {
    home: {
        path: 'home',
        name: 'home',
        meta: { title: '' },
        component: () => import('../views/Home.vue'),
    },
    userlist: {
        path: 'userlist',
        name: 'userlist',
        meta: { title: '' },
        component: () => import('../views/userManage/UserList.vue'),
    },
    factory: {
        path: 'factory',
        name: 'factory',
        meta: { title: '' },
        component: () => import('../views/userManage/Factory.vue'),
    },
    usergroup: {
        path: 'usergroup',
        name: 'usergroup',
        meta: {title: ''},
        component: () => import('../views/authManage/UserGroup.vue'),
    },
    datainfo: {
        path: 'datainfo',
        name: 'datainfo',
        meta: { title: '' },
        component: () => import('../views/systemManage/DataInfo.vue'),
    },
    role: {
        path: 'role',
        name: 'role',
        meta: {title: ''},
        component: () => import('../views/authManage/Role.vue'),
    },
    resource: {
        path: 'resource',
        name: 'resource',
        meta: {title: ''},
        component: () => import('../views/authManage/Resource.vue'),
    },
    operationlog: {
        path: 'operationlog',
        name: 'operationlog',
        meta: {title: ''},
        component: () => import('../views/logManage/OperationLog.vue')
    },
    eqinfo: {
        path: 'eqinfo',
        name: 'eqinfo',
        meta: {title: ''},
        component: () => import('../views/equipManage/EqInfo.vue')
    },
    eqmap: {
        path: 'eqmap',
        name: 'eqmap',
        meta: {title: ''},
        component: () => import('../views/equipManage/EqMap.vue')
    },
    paramrecord: {
        path: 'paramrecord',
        name: 'paramrecord',
        meta: {title: ''},
        component: () => import('../views/equipManage/ParamRecord.vue')
    },
    rtdata: {
        path: 'rtdata',
        name: 'rtdata',
        meta: {title: ''},
        component: () => import('../views/dataAnalysis/RtData.vue')
    },
    hisdata: {
        path: 'hisdata',
        name: 'hisdata',
        meta: {title: ''},
        component: () => import('../views/dataAnalysis/HisData.vue')
    },
    cycledata: {
        path: 'cycledata',
        name: 'cycledata',
        meta: {title: ''},
        component: () => import('../views/dataAnalysis/CycleData.vue')
    },
    normaldist: {
        path: 'normaldist',
        name: 'normaldist',
        meta: {title: ''},
        component: () => import('../views/dataAnalysis/NormalDist.vue')
    },
    attachmanage: {
        path: 'attachmanage',
        name: 'attachmanage',
        meta: {title: ''},
        component: () => import('../views/dataManage/AttachManage.vue')
    },
    datamanage: {
        path: 'datamanage',
        name: 'datamanage',
        meta: {title: ''},
        component: () => import('../views/dataManage/DataManage.vue')
    },
    copperdatamanage: {
        path: 'copperdatamanage',
        name: 'copperdatamanage',
        meta: {title: ''},
        component: () => import('../views/dataManage/CopperDataManage.vue')
    },
    productmanage:{
        path: 'productmanage',
        name: 'productmanage',
        meta:{title: ''},
        component: () => import('../views/productManage/ProductManage.vue')
    },
    productcodecheck:{
        path:'productcodecheck',
        name:'productcodecheck',
        meta:{title:''},
        component: () => import('../views/productManage/ProductCodeCheck.vue')
    },
    messagemanage:{
        path: 'messagemanage',
        name: 'messagemanage',
        meta:{title: ''},
        component: () => import('../views/messageManage/MessageManage.vue')
    },
    projectlist:{
        path:'projectlist',
        name:'projectlist',
        meta:{title:''},
        component:()=>import('../views/projectManage/ProjectList.vue')
    },
    orderlist:{
        path:'orderlist',
        name:'orderlist',
        meta:{title:''},
        component:()=>import('../views/projectManage/OrderList.vue')
    },
    menumanage:{
        path:'menumanage',
        name:'menumanage',
        meta:{title:''},
        component:()=>import('../views/authManage/MenuManage.vue')
    },
    copperrtdata:{
        path:'copperrtdata',
        name:'copperrtdata',
        meta:{title:''},
        component:()=>import('../views/copperManage/copperRtData.vue')
    },
    coppertimedata:{
        path:'coppertimedata',
        name:'coppertimedata',
        meta:{title:''},
        component:()=>import('../views/copperManage/copperTimeData.vue')
    },
    copperhisdata:{
        path:'copperhisdata',
        name:'copperhisdata',
        meta:{title:''},
        component:()=>import('../views/copperManage/CopperHisData.vue')
    },
    coppercycledata:{
        path:'coppercycledata',
        name:'coppercycledata',
        meta:{title:''},
        component:()=>import('../views/copperManage/CopperCycleData.vue')
    }

}

const createRouter = () => new Router({
    mode: 'history',
    routes: commonRoutes,
})

const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher
}

export default router
