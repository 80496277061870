import { LoadingBar } from 'view-design'
import router from './router'
import store from './store'
import createRoutes from '@/utils/createRoutes'
import { getDocumentTitle, resetTokenAndClearUser } from './utils'
import axios from 'axios'

// 是否有菜单数据
let hasMenus = false
router.beforeEach(async (to, from, next) => {
    document.title = getDocumentTitle(to.meta.title)
    LoadingBar.start()
    if (localStorage.getItem('token')) {
        if (to.path === '/login') {
            next({ path: '/' })
        } else if (hasMenus) {
            next()
        } else {
            try {
                // 这里可以用 await 配合请求后台数据来生成路由
                //const routes = createRoutes(data)
                let userId = localStorage.getItem('id')
                //const menuList = await axios.get('/menu/getMenuItems?userId=' + userId)
                //const routes = createRoutes(menuList.data.data)
                
                const menuList = JSON.parse(localStorage.getItem('menu'))
                const routes = createRoutes(menuList)
                // 动态添加路由
                router.addRoutes(routes)
                hasMenus = true
                next({ path: to.path || '/' })
            } catch (error) {
                resetTokenAndClearUser()
                next(`/login?redirect=${to.path}`)
            }
        }
    } else {
        hasMenus = false
        if (to.path === '/login') {
            next()
        } else {
            next(`/login?redirect=${to.path}`)
        }
    }
})

router.afterEach(() => {
    LoadingBar.finish()
})