import Vue from 'vue'
import axios from 'axios'
import ViewUI from 'view-design'
import ElementUI from 'element-ui'
import App from './App'
import store from './store'
import router from './router'
import BaiduMap from 'vue-baidu-map'
import echarts from "echarts";
//import Plugin from 'v-fit-columns';

import 'view-design/dist/styles/iview.css'
import './permission'
import 'element-ui/lib/theme-chalk/index.css'


Vue.config.productionTip = false
Vue.config.devtools = false;
Vue.use(ViewUI)
Vue.use(ElementUI)
//Vue.use(Plugin);//用于解决el-table的表头宽度自适应，但有bug

Vue.use(BaiduMap, {

    // ak 是在百度地图开发者平台申请的密钥
    ak: 'thZcycPLPE6GLnrkN9qnyRQ2x9CmFXGw'
  })

Vue.prototype.$axios = axios
Vue.prototype.$echarts=echarts

//axios.defaults.baseURL='http://127.0.0.1:8081/'
// axios.defaults.baseURL='http://10.65.1.100:8081/'
axios.defaults.baseURL='http://60.191.72.227:8081/'
new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
})
